.header {
  & th {
    padding: 12px 15px;
  }
}

.table-body {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
